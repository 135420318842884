import React from 'react';
import { SEO } from 'components';
import {
  HomeTop,
  WhoWeAre,
  WhatWeDo,
  HomeMap,
  Services,
  ContactHome
} from 'components/Home';

export default function Home() {
  return (
    <>
      <SEO title="Nexodata" />
      <HomeTop />
      <WhoWeAre />
      <WhatWeDo />
      <HomeMap />
      <Services />
      <ContactHome />
    </>
  );
}
